import { useEffect, useRef, useState, useCallback } from "react";
import { LayerGroup, MapContainer, ScaleControl, LayersControl, TileLayer, WMSTileLayer, } from "react-leaflet";
import {
  getFlights, getMinimalLightnings, getIncidents
} from "../../api/apiService";
import { getNordicReflectivityId, threddsMetUrl } from "../../api/apiServiceThirdParty";
import { FlightMarker, RemoveActiveCallSign, RemoveEstimatedPositions } from "../../components/FlightMarker";
import { LightningMarker } from "../../components/LightningMarker";
import { Flight, Lightning } from "../../model/model";
import { displayAllFlightTrails, displayFlightTrail } from "../../utils/FlightUtil";
import { GeoJSON } from 'react-leaflet';
import "leaflet-rotatedmarker";
import * as turf from '@turf/turf';
import { reldasPolygon, maskPolygon, outStyle } from './mapData';
import UserProfileControl from "../../components/UserProfile/UserProfile";
import SettingsControl from '../../components/SettingsControl/SettingsControl';
import IncidentControl from '../../components/IncidentControl/IncidentControl';
import { Incident } from "../../model/model";
import IncidentMarkers from '../../components/IncidentWarningSign/IncidentMarker';

const { Overlay } = LayersControl;

function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout | null = null;
  return function executedFunction(...args: any[]) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

function MapPage() {
  const [flightData, setFlightData] = useState<Flight[]>([]);
  const [activeFilter, setActiveFilter] = useState<string>('');
  const [filteredFlightData, setFilteredFlightData] = useState<Flight[]>([]);
  const [lightningData, setLightningData] = useState<Lightning[]>([]);
  const [flightTrails, setFlightTrails] = useState<Flight[][]>([]);
  const [estimatedPositions, setEstimatedPositions] = useState<Flight[]>([]);
  const [activeCallSigns, setActiveCallSigns] = useState<string[]>([]);
  const prevActiveCallSigns = useRef<string[]>([]);
  const [clickedCallSign, setClickedCallSign] = useState<string>('');

  const [nordicReflectivityId, setNordicReflectivityId] = useState<string>();
  const nordicReflectivityRef = useRef<any>(null);

  const [incidents, setIncidents] = useState<Incident[]>([]);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const fetchLightningData = useCallback(debounce(async () => {
    try {
      const lightnings: Lightning[] = await getMinimalLightnings();
      setLightningData(lightnings);
    } catch (error) {
      console.error("Error fetching lightning data:", error);
      setLightningData([]);
    }
  }, 1000), []); // 1000ms debounce time

  useEffect(() => {
    const getFlightData = async (activeFilter: string) => {
      try {
        const flights: Flight[] = await getFlights();
        if (activeFilter) {
          const filteredFlights = flights.filter(flight =>
            flight.callsign?.toLowerCase().includes(activeFilter.toLowerCase()) ||
            flight.hexident?.toLowerCase().includes(activeFilter.toLowerCase()) ||
            flight.regnum?.toLowerCase().includes(activeFilter.toLowerCase()) ||
            flight.source?.toLowerCase().includes(activeFilter.toLowerCase())
          );
          setFilteredFlightData(filteredFlights);
          setFlightData([]);
        } else {
          setFlightData(flights);
          setFilteredFlightData([]);
        }
      } catch (error) {
        console.error("Error fetching flight data:", error);
      }
    };

    getFlightData(activeFilter);

    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Set up new interval
    intervalRef.current = setInterval(() => {
      getFlightData(activeFilter);
    }, 5000);

    // Cleanup function
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [activeFilter]);


  useEffect(() => {
    const activePlanesHaveChanged = activeCallSigns.length !== prevActiveCallSigns.current.length || activeCallSigns[0] !== prevActiveCallSigns.current[0];
    if (activePlanesHaveChanged) {
      displayFlightTrail(clickedCallSign, setFlightTrails, flightTrails, setActiveCallSigns, activeCallSigns);
      prevActiveCallSigns.current = activeCallSigns;
    }
    const interval = setInterval(async function () {
      await displayAllFlightTrails(setFlightTrails, flightTrails, setActiveCallSigns, activeCallSigns);
    }, 6000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [activeCallSigns, flightTrails, clickedCallSign]);

  useEffect(() => {
    fetchLightningData();
    const intervalId = setInterval(fetchLightningData, 10000);
    return () => clearInterval(intervalId);
  }, [fetchLightningData]);

  useEffect(() => {
    const getNordicReflectivityData = async () => {
      const id: string = await getNordicReflectivityId();
      setNordicReflectivityId(id);
    };

    getNordicReflectivityData();

    const interval = setInterval(async function () {
      await getNordicReflectivityData();
    }, 90000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (nordicReflectivityRef.current) {
      const url = `${threddsMetUrl}/wms/${nordicReflectivityId}`;
      nordicReflectivityRef.current.setUrl(url);
    }
  }, [nordicReflectivityId]);


  useEffect(() => {
    const fetchIncidents = async () => {
      const oneDayAgo = new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString();
      try {
        const fetchedIncidents = await getIncidents(0, 100, {
          from_date: oneDayAgo,
        });
        setIncidents(fetchedIncidents);
      } catch (error) {
        console.error("Failed to fetch incidents:", error);
      }
    };

    fetchIncidents();
    // Fetch incidents every 5 sec 
    const interval = setInterval(fetchIncidents, 5 * 1000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <MapContainer
        zoom={7}
        minZoom={4}
        maxZoom={18}
        center={{ lat: 60, lng: 10 }}
        preferCanvas={true}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        <UserProfileControl />
        <SettingsControl />
        <IncidentControl incidentCount={incidents.length} />


        <ScaleControl position="bottomleft" imperial={false} />
        <LayersControl position="topright">

          <TileLayer
            attribution='&copy; <a href="https://cartodb.com/attributions">CartoDB</a>'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            maxZoom={18}
          />


          <Overlay checked name="Incidents">
            <IncidentMarkers incidents={incidents} />
          </Overlay>

          {
            <Overlay checked name="Radar">
              <LayerGroup>
                <WMSTileLayer
                  attribution='<a href="https://www.met.no/en">MET Norway</a>'
                  version="1.3.0"
                  layers="equivalent_reflectivity_factor"
                  opacity={0.5}
                  format="image/png"
                  transparent={true}
                  url={`${threddsMetUrl}/wms/${nordicReflectivityId}`}
                  ref={nordicReflectivityRef}
                />
              </LayerGroup>
            </Overlay>
          }

          {
            <Overlay checked name="Lightning">
              <LayerGroup>
                {lightningData?.map((lightning: Lightning) => {
                  return LightningMarker(lightning, setEstimatedPositions);
                })}
                <div className="leaflet-bottom leaflet-left">
                  <div className="info-container">
                    <span># sferics: {lightningData.length}</span>
                    <span># ac: {flightData.length}</span>
                    <span># incidents: {incidents.length}</span>
                    <span>Radar: {nordicReflectivityId?.slice(-19, -3)}</span>
                  </div>
                </div>
                <RemoveActiveCallSign setActiveCallSign={setActiveCallSigns} setFlightTrails={setFlightTrails} />
                <RemoveEstimatedPositions setEstimatedPositions={setEstimatedPositions} />
              </LayerGroup>
            </Overlay>
          }

          {
            <Overlay checked name="Aircraft">
              <LayerGroup>
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Callsign or hexident"
                  onChange={(e) => {
                    setActiveFilter(e.target.value);
                  }}
                />
                {!activeFilter && flightData?.map((flight: Flight) => {
                  const point = turf.point([flight.longitude, flight.latitude]);
                  if (reldasPolygon && turf.booleanPointInPolygon(point, reldasPolygon))
                    return FlightMarker(
                      flight,
                      flightTrails,
                      setClickedCallSign,
                      setActiveCallSigns,
                      activeCallSigns,
                      estimatedPositions,);
                })}
                {activeFilter && filteredFlightData?.map((flight: Flight) => {
                  return FlightMarker(
                    flight,
                    flightTrails,
                    setClickedCallSign,
                    setActiveCallSigns,
                    activeCallSigns,
                    estimatedPositions,);
                })}
              </LayerGroup>
            </Overlay>
          }

        </LayersControl>

        <GeoJSON data={maskPolygon as GeoJSON.GeoJsonObject} style={outStyle} />


      </MapContainer >

    </div >
  );
}

export default MapPage;
