// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FilterPanel.module.css */
.FilterPanel_filterPanel__t4\\+M3 {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.FilterPanel_filterInput__uhbP- {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
}`, "",{"version":3,"sources":["webpack://./src/components/FilterPanel/FilterPanel.module.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":["/* FilterPanel.module.css */\n.filterPanel {\n    display: flex;\n    justify-content: center;\n    gap: 10px;\n    margin-bottom: 20px;\n}\n\n.filterInput {\n    padding: 5px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    background-color: #f8f8f8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterPanel": `FilterPanel_filterPanel__t4+M3`,
	"filterInput": `FilterPanel_filterInput__uhbP-`
};
export default ___CSS_LOADER_EXPORT___;
