import React, { useRef, useEffect, useState, useMemo } from 'react';
import antonov124TopView from '../../assets/Antonov_124_top.png';
import './DamageDiagram.css';
import { Damage } from '../../model/model';


const redColormap = [
  [255, 245, 240], [254, 224, 210], [252, 187, 161], [252, 146, 114],
  [251, 106, 74], [239, 59, 44], [203, 24, 29], [165, 15, 21],
  [103, 0, 13]
];

interface DamageDiagramProps {
  damageData: Damage
  onClose: () => void;
}

const DamageDiagram: React.FC<DamageDiagramProps> = ({ damageData, onClose }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [locationSource, setLocationSource] = useState<string>("");
  const [damageScoreSource, setDamageScoreSource] = useState<string>("");

  useEffect(() => {
    setLocationSource(damageData.actual_damage_location ? "Actual" : damageData.pred_damage_location ? "Predicted" : "");
    setDamageScoreSource(damageData.actual_damage_score ? "Actual" : damageData.pred_damage_score ? "Predicted" : "");
  }, [damageData]);

  const intensities = useMemo(() => {
    let rawData: string | any[];
    if (damageData.actual_damage_location) {
      rawData = damageData.actual_damage_location;
    } else if (damageData.pred_damage_location) {
      rawData = damageData.pred_damage_location;
    } else {
      return [];
    }
    if (typeof rawData === 'string') {
      return rawData.split('').map(Number);
    } else if (Array.isArray(rawData)) {
      const arrayData = rawData as any[];
      if (arrayData.length > 0) {
        const firstElement = arrayData[0];
        return firstElement.split('').map(Number);
      }
    }
    return [];
  }, [damageData.actual_damage_location, damageData.pred_damage_location]);

  const bins = damageData.damage_location_bins ?? "0,40,68,135,180,225,292,320,360";
  const angles = bins.split(',').map(Number);

  const degreesToRadians = (degrees: number) => {
    let radians = ((degrees - 90) * Math.PI) / 180;
    return (radians + 2 * Math.PI) % (2 * Math.PI);
  };

  const getIntensityColor = (intensity: number): string => {
    const normalizedIntensity = intensity / 9;
    const index = Math.min(Math.floor(normalizedIntensity * (redColormap.length - 1)), redColormap.length - 1);
    const [r, g, b] = redColormap[index];
    return `rgb(${r}, ${g}, ${b})`;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const outerRadius = Math.min(centerX, centerY) - 10;
    const innerRadius = outerRadius * 0.85;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    intensities.forEach((intensity: number, index: number) => {
      const startAngle = degreesToRadians(angles[index]);
      const endAngle = degreesToRadians(angles[(index + 1) % angles.length]);

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle);
      ctx.lineTo(centerX, centerY);
      ctx.fillStyle = getIntensityColor(intensity);
      ctx.fill();
    });

    ctx.beginPath();
    ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI);
    ctx.fillStyle = 'white';
    ctx.fill();

  }, [intensities, angles]);

  const damageScore = damageData.actual_damage_score ?? damageData.pred_damage_score ?? "Unknown";

  return (
    <div className="damage-diagram">
      <div className="damage-diagram-content">
        <h2 className="damage-diagram-title">{locationSource} Damage Location</h2>
        <div className="diagram-container">
          <canvas ref={canvasRef} width={400} height={400} />
          <img
            src={antonov124TopView}
            alt="Aircraft Diagram"
            className="damage-diagram-image"
          />
        </div>
        <div className="probability-legend">
          <div className="legend-gradient"></div>
          <div className="legend-labels">
            <span>Low</span>
            <span>Medium</span>
            <span>High</span>
          </div>
        </div>
        {damageScore && <div className="damage-score-container">
          <div className="damage-score">{damageScoreSource} Damage (0-5): {damageScore}</div>
        </div>}
      </div>
    </div>
  );
};

export default DamageDiagram;
