// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-tooltip {
  color: #FFF;
  background: #3E3E3D;
  border: none;
  border-radius: 2em;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  padding: 1em;
  max-height: 7em;
  line-height: 1em;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  display: none;
}

:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

@media (prefers-color-scheme: dark) {
  .map-tiles {
      filter:var(--map-tiles-filter, none);
}
}`, "",{"version":3,"sources":["webpack://./src/styles/custom-leaflet.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,yCAAyC;EACzC,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,0GAA0G;AAC5G;;AAEA;EACE;MACI,oCAAoC;AAC1C;AACA","sourcesContent":[".leaflet-tooltip {\n  color: #FFF;\n  background: #3E3E3D;\n  border: none;\n  border-radius: 2em;\n  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);\n  padding: 1em;\n  max-height: 7em;\n  line-height: 1em;\n}\n\n.leaflet-tooltip-left:before,\n.leaflet-tooltip-right:before {\n  display: none;\n}\n\n:root {\n  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);\n}\n\n@media (prefers-color-scheme: dark) {\n  .map-tiles {\n      filter:var(--map-tiles-filter, none);\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
