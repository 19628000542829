// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* IncidentEdit.module.css */
.IncidentAdd_form__nvIaf {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 40px;
    width: 600px;
}

.IncidentAdd_textField__RUsg8 {
    width: 100%;
}

.IncidentAdd_column__Xmsso {
    display: flex;
    flex-direction: column;
    gap: 20px;}

.IncidentAdd_row__CvEec {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
 
  .IncidentAdd_buttonContainer__mPXyr {
    display: flex;
    justify-content: space-between;
  }
  
  .IncidentAdd_buttonSubGroup__TUd2o {
    display: flex;
    gap: 10px;
  }


`, "",{"version":3,"sources":["webpack://./src/components/IncidentAdd/IncidentAdd.module.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS,CAAC;;AAEd;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,SAAS;EACX","sourcesContent":["/* IncidentEdit.module.css */\n.form {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    gap: 40px;\n    width: 600px;\n}\n\n.textField {\n    width: 100%;\n}\n\n.column {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;}\n\n.row {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    align-items: center;\n  }\n \n  .buttonContainer {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .buttonSubGroup {\n    display: flex;\n    gap: 10px;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `IncidentAdd_form__nvIaf`,
	"textField": `IncidentAdd_textField__RUsg8`,
	"column": `IncidentAdd_column__Xmsso`,
	"row": `IncidentAdd_row__CvEec`,
	"buttonContainer": `IncidentAdd_buttonContainer__mPXyr`,
	"buttonSubGroup": `IncidentAdd_buttonSubGroup__TUd2o`
};
export default ___CSS_LOADER_EXPORT___;
