// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #ffffff;;
  font-family: Arial, sans-serif;
}

.landing-page h1 {
  color: #000000;
  margin-bottom: 2rem;
}

.login-form {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
}

.form-group {
  margin-bottom: 0.75rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.25rem;
  color: #333;
  font-size: 0.9rem;
}

.form-group input {
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  padding: 0.6rem;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 0.5rem;
  box-sizing: border-box;
}

.submit-button:hover {
  background-color: #1557b0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/LandingPage/LandingPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,wCAAwC;EACxC,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,iCAAiC;EACjC,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".landing-page {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh;\n  background-color: #ffffff;;\n  font-family: Arial, sans-serif;\n}\n\n.landing-page h1 {\n  color: #000000;\n  margin-bottom: 2rem;\n}\n\n.login-form {\n  background-color: white;\n  padding: 1.5rem;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  width: 100%;\n  max-width: 320px;\n}\n\n.form-group {\n  margin-bottom: 0.75rem;\n}\n\n.form-group label {\n  display: block;\n  margin-bottom: 0.25rem;\n  color: #333;\n  font-size: 0.9rem;\n}\n\n.form-group input {\n  width: 100%;\n  padding: 0.4rem;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 0.9rem;\n  box-sizing: border-box;\n}\n\n.submit-button {\n  width: 100%;\n  padding: 0.6rem;\n  background-color: #1a73e8;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  font-size: 0.9rem;\n  cursor: pointer;\n  transition: background-color 0.2s;\n  margin-top: 0.5rem;\n  box-sizing: border-box;\n}\n\n.submit-button:hover {\n  background-color: #1557b0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
