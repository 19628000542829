import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import antonov124TopView from '../../assets/Antonov_124_top.png';
import './DamageVisualization.css';
import { Damage } from '../../model/model';

export interface DamageVisualizationRef {
    getIntensities: () => number[];
    getDamageScore: () => string;
}

const redColormap = [
    [255, 245, 240], [254, 224, 210], [252, 187, 161], [252, 146, 114],
    [251, 106, 74], [239, 59, 44], [203, 24, 29], [165, 15, 21],
    [103, 0, 13]
];

interface DamageVisualizationProps {
    damageData: Damage
    onIntensityUpdate?: (intensities: number[]) => void;
    ref?: React.RefObject<DamageVisualizationRef>;
}

const DamageVisualization = forwardRef<DamageVisualizationRef, DamageVisualizationProps>(({
    damageData,
    onIntensityUpdate,
}, ref) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [localIntensities, setLocalIntensities] = useState<number[]>([]);
    const [locationSource, setLocationSource] = useState<string>("Predicted");
    const [editableDamageScore, setEditableDamageScore] = useState<string>("");
    const [damageScoreSource, setDamageScoreSource] = useState<string>("Predicted");
    const [pulseOpacity, setPulseOpacity] = useState(0);

    // Parse intensities only once when predDamageLocation changes
    useEffect(() => {
        const parsedIntensities = (() => {
            if (damageData.actual_damage_location) {
                return damageData.actual_damage_location.split('').map(Number);
            } else if (damageData.pred_damage_location) {
                return damageData.pred_damage_location.split('').map(Number);
            }
            return [];
        })();
        setLocalIntensities(parsedIntensities);
        setLocationSource(damageData.actual_damage_location ? "Actual" : "Predicted");
    }, [damageData]);

    const bins = damageData.damage_location_bins ?? "0,40,68,135,180,225,292,320,360";
    const angles = bins.split(',').map(Number);

    const degreesToRadians = (degrees: number) => {
        let radians = ((degrees - 90) * Math.PI) / 180;
        return (radians + 2 * Math.PI) % (2 * Math.PI);
    };

    const getIntensityColor = (intensity: number): string => {
        const normalizedIntensity = intensity / 9;
        const index = Math.min(Math.floor(normalizedIntensity * (redColormap.length - 1)), redColormap.length - 1);
        const [r, g, b] = redColormap[index];
        return `rgb(${r}, ${g}, ${b})`;
    };

    useEffect(() => {
        let animationFrameId: number;
        let startTime: number;

        const animate = (timestamp: number) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const opacity = Math.sin((elapsed / 500) * Math.PI) * 0.3 + 0.3; // Oscillate between 0 and 0.6
            setPulseOpacity(opacity);

            animationFrameId = requestAnimationFrame(animate);
        };

        if (hoveredIndex !== null) {
            animationFrameId = requestAnimationFrame(animate);
        }

        return () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };
    }, [hoveredIndex]);

    const drawArcs = useCallback((ctx: CanvasRenderingContext2D, centerX: number, centerY: number, outerRadius: number, innerRadius: number) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        localIntensities.forEach((intensity: number, index: number) => {
            const startAngle = degreesToRadians(angles[index]);
            const endAngle = degreesToRadians(angles[(index + 1) % angles.length]);

            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle);
            ctx.lineTo(centerX, centerY);
            ctx.fillStyle = getIntensityColor(intensity);
            ctx.fill();

            if (index === hoveredIndex) {
                // Pulsing highlight effect for hovered sector
                ctx.globalAlpha = pulseOpacity;
                ctx.fillStyle = 'rgb(103, 0, 13)';
                ctx.fill();
                ctx.globalAlpha = 1;

                // Add a subtle glow
                ctx.shadowColor = 'rgba(255, 255, 255, 0.8)';
                ctx.shadowBlur = 10;
                ctx.strokeStyle = 'white';
                ctx.lineWidth = 2;
                ctx.stroke();
                ctx.shadowBlur = 0;
            }
        });
        // draw a white circle at the center
        ctx.globalAlpha = 1;
        ctx.beginPath();
        ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI);
        ctx.fillStyle = 'white';
        ctx.fill();
    }, [localIntensities, angles, hoveredIndex, pulseOpacity]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const outerRadius = Math.min(centerX, centerY) - 10;
        const innerRadius = outerRadius * 0.85;

        drawArcs(ctx, centerX, centerY, outerRadius, innerRadius);

        const handleMouseMove = (event: MouseEvent) => {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            const dx = x - centerX;
            const dy = y - centerY;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance > innerRadius && distance < outerRadius) {
                let angle = Math.atan2(dy, dx);
                angle = (angle + 2 * Math.PI) % (2 * Math.PI);
                angle = (angle * 180) / Math.PI + 90;
                if (angle > 360) angle -= 360;

                const index = angles.findIndex((startAngle, i) => {
                    const endAngle = angles[(i + 1) % angles.length];
                    return angle >= startAngle && angle < endAngle;
                });

                if (index !== hoveredIndex) {
                    setHoveredIndex(index);
                }
            } else {
                setHoveredIndex(null);
            }
        };

        const handleClick = (event: MouseEvent) => {
            event.preventDefault(); // Prevent default behavior

            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            const dx = x - centerX;
            const dy = y - centerY;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance > innerRadius && distance < outerRadius) {
                let angle = Math.atan2(dy, dx);
                angle = (angle + 2 * Math.PI) % (2 * Math.PI);
                angle = (angle * 180) / Math.PI + 90;
                if (angle > 360) angle -= 360;

                const index = angles.findIndex((startAngle, i) => {
                    const endAngle = angles[(i + 1) % angles.length];
                    return angle >= startAngle && angle < endAngle;
                });

                if (index !== -1) {
                    const updatedIntensities = [...localIntensities];
                    updatedIntensities[index] = updatedIntensities[index] === 9 ? 0 : 9;
                    setLocalIntensities(updatedIntensities);
                    setLocationSource("Actual");
                    if (onIntensityUpdate) {
                        onIntensityUpdate(updatedIntensities);
                    }
                }
            }
        };

        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('click', handleClick);

        return () => {
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('click', handleClick);
        };
    }, [drawArcs, localIntensities, angles, onIntensityUpdate]);

    useImperativeHandle(ref, () => ({
        getIntensities: () => localIntensities,
        getDamageScore: () => editableDamageScore
    }));

    useEffect(() => {
        if (damageData.actual_damage_score) {
            setEditableDamageScore(damageData.actual_damage_score.toString());
            setDamageScoreSource("Actual");
        } else if (damageData.pred_damage_score !== null && damageData.pred_damage_score !== undefined) {
            setEditableDamageScore(damageData.pred_damage_score.toString());
            setDamageScoreSource("Predicted");
        } else {
            setEditableDamageScore("Unknown");
            setDamageScoreSource("Predicted");
        }
    }, [damageData]);

    const handleDamageScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === 'Unknown' || value === '' || (parseInt(value) >= 0 && parseInt(value) <= 5)) {
            setEditableDamageScore(value);
        }
    };

    const handleDamageScoreBlur = () => {
        if (editableDamageScore === 'Unknown') {
            setDamageScoreSource("Predicted");
            return;
        }
        const value = parseInt(editableDamageScore);
        setDamageScoreSource("Actual");
        if (isNaN(value) || value < 0) {
            setEditableDamageScore('0');
        } else if (value > 5) {
            setEditableDamageScore('5');
        } else {
            setEditableDamageScore(value.toString());
        }
        // Here you can add logic to update the damageData if needed
        // For example, you might want to call a prop function to update the parent component
        // onDamageScoreUpdate(editableDamageScore);
    };

    return (
        <div className="damage-visualization">
            <h3 className="damage-visualization-title">{locationSource} Damage Location</h3>
            <div className="visualization-container">
                <canvas ref={canvasRef} width={300} height={300} />
                <img
                    src={antonov124TopView}
                    alt="Aircraft Diagram"
                    className="damage-visualization-image no-select"
                />
            </div>
            <div className="probability-legend">
                <div className="legend-gradient"></div>
                <div className="legend-labels">
                    <span>Low</span>
                    <span>Medium</span>
                    <span>High</span>
                </div>
            </div>
            <div className="damage-score">
                <label htmlFor="damageScoreInput">{damageScoreSource} Damage (0-5):</label>
                <input
                    id="damageScoreInput"
                    type="text"
                    value={editableDamageScore}
                    onChange={handleDamageScoreChange}
                    onBlur={handleDamageScoreBlur}
                    className="damage-score-input"
                />
            </div>
        </div>
    );
});

export default DamageVisualization;
