// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* IncidentEdit.module.css */
.IncidentEdit_form__P3dmx {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 40px;
  width: 600px;
  min-height: 750px;
  position: relative;
  padding-bottom: 60px;
  /* Add space for the buttons */
}

.IncidentEdit_textField__\\+v3se {
  width: 100%;
}

.IncidentEdit_column__QpeSr {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.IncidentEdit_row__z2z8y {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 30px;
}

.IncidentEdit_buttonContainer__Yujdb {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
}

.IncidentEdit_buttonSubGroup__z6py4 {
  display: flex;
  gap: 10px;
}

.IncidentEdit_attachmentItem__PmEwX {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.IncidentEdit_deleteIcon__19ryn {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/IncidentEdit/IncidentEdit.module.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,SAAS;EACT,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;EACpB,8BAA8B;AAChC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["/* IncidentEdit.module.css */\n.form {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  gap: 40px;\n  width: 600px;\n  min-height: 750px;\n  position: relative;\n  padding-bottom: 60px;\n  /* Add space for the buttons */\n}\n\n.textField {\n  width: 100%;\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  gap: 20px;\n  margin-bottom: 30px;\n}\n\n.buttonContainer {\n  display: flex;\n  justify-content: space-between;\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  padding: 20px 0;\n}\n\n.buttonSubGroup {\n  display: flex;\n  gap: 10px;\n}\n\n.attachmentItem {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.deleteIcon {\n  margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `IncidentEdit_form__P3dmx`,
	"textField": `IncidentEdit_textField__+v3se`,
	"column": `IncidentEdit_column__QpeSr`,
	"row": `IncidentEdit_row__z2z8y`,
	"buttonContainer": `IncidentEdit_buttonContainer__Yujdb`,
	"buttonSubGroup": `IncidentEdit_buttonSubGroup__z6py4`,
	"attachmentItem": `IncidentEdit_attachmentItem__PmEwX`,
	"deleteIcon": `IncidentEdit_deleteIcon__19ryn`
};
export default ___CSS_LOADER_EXPORT___;
