// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incident-control {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.incident-control a {
  background-color: #fff;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.incident-control img {
  display: block;
  margin: 10px;
  border: 2px solid white;
  background-color: rgb(216, 216, 216);
  border-radius: 50%;
  transition: box-shadow;
}

.incident-control a:hover {
  background-color: #f0f0f0;
  border-color: #d0d0d0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.incident-control img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 255, 255);
}

.incident-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/IncidentControl/IncidentControl.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,uBAAuB;EACvB,oCAAoC;EACpC,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,yCAAyC;AAC3C;;AAEA;EACE,qBAAqB;EACrB,wCAAwC;EACxC,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".incident-control {\n  background-color: transparent;\n  cursor: pointer;\n  position: relative;\n  display: inline-block;\n}\n\n.incident-control a {\n  background-color: #fff;\n  border: 2px solid #e0e0e0;\n  border-radius: 50%;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  width: 40px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: all 0.3s ease;\n}\n\n.incident-control img {\n  display: block;\n  margin: 10px;\n  border: 2px solid white;\n  background-color: rgb(216, 216, 216);\n  border-radius: 50%;\n  transition: box-shadow;\n}\n\n.incident-control a:hover {\n  background-color: #f0f0f0;\n  border-color: #d0d0d0;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);\n}\n\n.incident-control img:hover {\n  transform: scale(1.1);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n  background-color: rgb(255, 255, 255);\n}\n\n.incident-badge {\n  position: absolute;\n  top: 0;\n  right: 0;\n  background-color: red;\n  color: white;\n  border-radius: 50%;\n  padding: 2px 5px;\n  font-size: 12px;\n  line-height: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
