// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incident-icon-container {
    background-color: transparent;
    cursor: pointer;
}

.incident-icon-wrapper {
}

.incident-icon-wrapper img {
    border: 2px solid rgb(0, 0, 0);
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s;
  }
`, "",{"version":3,"sources":["webpack://./src/components/IncidentWarningSign/IncidentWarningSign.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;AACA;;AAEA;IACI,8BAA8B;IAC9B,uBAAuB;IACvB,wCAAwC;IACxC,2BAA2B;EAC7B","sourcesContent":[".incident-icon-container {\n    background-color: transparent;\n    cursor: pointer;\n}\n\n.incident-icon-wrapper {\n}\n\n.incident-icon-wrapper img {\n    border: 2px solid rgb(0, 0, 0);\n    background-color: white;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n    transition: box-shadow 0.3s;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
