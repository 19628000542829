// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-control {
    background-color: transparent;
    cursor: pointer;
  }
  
  .settings-control img {
    display: block;
    margin: 10px;
    border-radius: 50%;
    border: 2px solid white;
    background-color: rgb(216, 216, 216);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s;
  }
  
  .settings-control img:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background-color: rgb(255, 255, 255);
  }`, "",{"version":3,"sources":["webpack://./src/components/SettingsControl/SettingsControl.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,oCAAoC;IACpC,wCAAwC;IACxC,2BAA2B;EAC7B;;EAEA;IACE,qBAAqB;IACrB,wCAAwC;IACxC,oCAAoC;EACtC","sourcesContent":[".settings-control {\n    background-color: transparent;\n    cursor: pointer;\n  }\n  \n  .settings-control img {\n    display: block;\n    margin: 10px;\n    border-radius: 50%;\n    border: 2px solid white;\n    background-color: rgb(216, 216, 216);\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n    transition: box-shadow 0.3s;\n  }\n  \n  .settings-control img:hover {\n    transform: scale(1.1);\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n    background-color: rgb(255, 255, 255);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
