import flightMarker from "../assets/flight.svg";
import flightMarkerNoreg from "../assets/flight-noreg.svg";
import flightStartPositionDot from "../assets/flight-start.svg";
import activeFlightMarker from "../assets/flight-active.svg";
import estimatedFlightMarker from "../assets/flight-yellow.svg";
import activeFlightShadowMarker from "../assets/flight-shadow.svg";

import lightningIC from "../assets/lightningIC.svg";
import lightningCG from "../assets/lightningCG.svg";
import helicopter from "../assets/helicopter.svg";
import activeHelicopter from "../assets/helicopter-active.svg";
import helicopterShadow from "../assets/helicopter-shadow.svg";
import { Icon } from "leaflet";

const flightIcon = new Icon({
  iconUrl: flightMarker,
  iconRetinaUrl: flightMarker,
  iconSize: [20, 29]
});

const flightIconNoreg = new Icon({
  iconUrl: flightMarkerNoreg,
  iconRetinaUrl: flightMarkerNoreg,
  iconSize: [20, 29]
});


const activeFlightIcon = new Icon({
  iconUrl: activeFlightMarker,
  iconRetinaUrl: activeFlightMarker,
  iconSize: [20, 29]
});

const estimateFlightIcon = new Icon({
  iconUrl: estimatedFlightMarker,
  iconRetinaUrl: estimatedFlightMarker,
  iconSize: [20, 29]
});

const activeFlightShadowIcon = new Icon({
  iconUrl: activeFlightShadowMarker,
  iconRetinaUrl: activeFlightShadowMarker,
  iconSize: [20, 29],
  iconAnchor: [5, 8]
});

const flightStartIcon = new Icon({
  iconUrl: flightStartPositionDot,
  iconRetinaUrl: flightStartPositionDot,
  iconSize: [20, 20]
});

const lightningIconIC = new Icon({
  iconUrl: lightningIC,
  iconRetinaUrl: lightningIC,
  iconSize: [20, 20]
});

const lightningIconCG = new Icon({
  iconUrl: lightningCG,
  iconRetinaUrl: lightningCG,
  iconSize: [20, 20]
});

const helicopterIcon = new Icon({
  iconUrl: helicopter,
  iconRetinaUrl: helicopter,
  iconSize: [20, 20]
});

const activeHelicopterIcon = new Icon({
  iconUrl: activeHelicopter,
  iconRetinaUrl: activeHelicopter,
  iconSize: [20, 20]
});

const helicopterShadowIcon = new Icon({
  iconUrl: helicopterShadow,
  iconRetinaUrl: helicopterShadow,
  iconSize: [20, 20],
  iconAnchor: [5, 8]
});

export {
  flightIcon,
  flightIconNoreg,
  flightStartIcon,
  activeFlightIcon,
  estimateFlightIcon,
  activeFlightShadowIcon,
  activeHelicopterIcon,
  helicopterIcon,
  lightningIconIC,
  lightningIconCG,
  helicopterShadowIcon,
}