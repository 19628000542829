// IncidentEdit.tsx
import React, { useState } from 'react';
import { Incident } from '../../model/model';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from './IncidentAdd.module.css';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSnackbar } from 'notistack';
import { Tabs, Tab } from '@material-ui/core';


interface IncidentAddProps {
    incident: Incident;
    onSubmit: (incident: Incident) => void;
}

const IncidentAdd: React.FC<IncidentAddProps> = ({ incident, onSubmit }) => {
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    const [radius, setRadius] = useState("10");
    const [timespan, setTimespan] = useState("100");
    const [editedIncident, setEditedIncident] = useState(incident);
    const [newIncidents, setNewIncidents] = useState<Incident[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => {
        let { name, value } = event.target;

        setEditedIncident(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit(editedIncident);
    };

    const handleCancel = () => {
        setEditedIncident(incident);
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
            handleSubmit(event);
        }
    };

    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(event.target.files?.[0] || null);
        // if (event.target.files?.[0]) {
        //     try {
        //         let new_incidents = await analyzeAcTrack(event.target.files?.[0], radius, timespan);
        //         setNewIncidents(new_incidents);
        //         enqueueSnackbar(`Success, found ${new_incidents.length} incidents.`, { variant: 'success' });
        //     }
        //     catch (error) {
        //         enqueueSnackbar(`Track analyzes failed: ${(error as Error).message}`, { variant: 'error' });
        //     }
        // }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form} onKeyDown={handleKeyDown}>
            <Tabs value={value} onChange={handleChangeTab}>
                <Tab label="Track" />
                <Tab label="Manual" />
            </Tabs>
            {value === 0 && (
                <div className={styles.column}>
                    <div className={styles.row}>
                        <TextField className={styles.textField} name="radius" label="Radius, km" value={radius} onChange={(e) => setRadius(e.target.value)} />
                        <TextField className={styles.textField} name="timespan" label="Timespan, sec" value={timespan} onChange={(e) => setTimespan(e.target.value)} />                </div>
                    <div className={styles.row}>
                        <input
                            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            style={{ display: 'none' }}
                            id="contained-button-file"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span">
                                Select file...
                            </Button>
                        </label>
                    </div>
                    {newIncidents.map((incident, index) => (
                        <div key={index} className={styles.row}>
                            <label>{index + 1} {incident.callsign} {incident.timestamp} {incident.distance} {incident.detected_by}</label>
                            <Button variant="contained" color="primary" onClick={() => onSubmit(incident)}>
                                Submit
                            </Button>
                        </div>
                    ))}
                </div>
            )}
            {value === 1 && (
                <div className={styles.column}>
                    <div className={styles.row}>
                        <TextField className={styles.textField} name="id" label="ID" value={editedIncident.id} disabled />
                        <TextField className={styles.textField} name="Timestamp" label="UTC" value={editedIncident.timestamp} />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <TextField name="hexident" label="Hexident" value={editedIncident.hexident} onChange={handleChange} />
                            <TextField name="callsign" label="Callsign" value={editedIncident.callsign} onChange={handleChange} />
                        </div>
                        <div className={styles.column}>
                            <TextField name="regnum" label="Registration" value={editedIncident.regnum} onChange={handleChange} />
                            <TextField name="flight_phase" label="Flight phase" value={editedIncident.flight_phase} onChange={handleChange} />
                        </div>
                        <div className={styles.column}>
                            <TextField name="damage_score" label="Damage pred" value={editedIncident.damage_score} onChange={handleChange} />
                            <TextField name="reported_damage" label="Damage reported" value={editedIncident.reported_damage} onChange={handleChange} />
                        </div>
                        <div className={styles.column}>
                            <TextField name="event_type" label="Event type" value={editedIncident.event_type} onChange={handleChange} />
                            <TextField name="AOG" label="AOG" value={editedIncident.AOG} onChange={handleChange} />
                        </div>
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={editedIncident.confirmed || false}
                                onChange={handleChange}
                                name="confirmed"
                                color="primary"
                            />
                        }
                        label="Confirmed"
                    />
                    <TextField
                        name="comment"
                        label="Comment"
                        multiline
                        minRows={4}
                        variant="outlined"
                        fullWidth
                        value={editedIncident.comment || ''}
                        onChange={handleChange}
                    />

                    <div className={styles.buttonContainer}>
                        <div className={styles.buttonSubGroup}>
                            <Button onClick={handleCancel}>Reset</Button>
                            <Button type="submit">Submit</Button>
                        </div>
                    </div>
                </div>
            )}


        </form>
    );
};

export default IncidentAdd;