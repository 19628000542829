import React, { useState } from 'react';
import { LayerGroup, Marker, Popup } from 'react-leaflet';
import { Incident } from '../../model/model';
import { incidentIcon } from './IncidentWarningSign';

interface IncidentMarkersProps {
  incidents: Incident[];
}

const IncidentMarkers: React.FC<IncidentMarkersProps> = ({ incidents }) => {

  return (
    <LayerGroup>
      {incidents.map((incident) => (
        <Marker
          key={incident.id}
          position={[incident.latitude, incident.longitude]}
          icon={incidentIcon}
          zIndexOffset={10000}
        >
          <Popup>
            <h3>{incident.regnum}</h3>
            <p>Time: {incident.timestamp.slice(0, 19).replace('T', ' ')}</p>
            <p>Callsign: {incident.callsign}</p>
            <p>Hexident: {incident.hexident}</p>
            <p>Source: {incident.detected_by}</p>
          </Popup>
        </Marker>
      ))}
    </LayerGroup>
  );
};

export default IncidentMarkers;