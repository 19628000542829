import { Control, ControlOptions } from "leaflet";
import { useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import settingsIcon from '../../assets/icons8-settings-50.png';
import './SettingsControl.css';
import SettingsPopup from './SettingsPopup';
import Cookies from 'js-cookie';

const SettingsControl = (props: ControlOptions) => {
  const map = useMap();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const SettingsControl = Control.extend({
      onAdd: () => {
        const container = document.createElement('div');
        container.className = "settings-control";
        container.title = "Settings";
        container.innerHTML = `
          <img src="${settingsIcon}" alt="Settings" width="38" height="38" />
        `;
        container.onclick = () => setShowPopup(true);
        return container;
      }
    });

    const controlOptions: ControlOptions = {
      position: 'bottomleft',
      ...props
    };

    const control = new SettingsControl(controlOptions);
    control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map, props]);

  const handleSaveSettings = (lightningHistoryDays: number) => {
    Cookies.set('lightningHistoryDays', lightningHistoryDays.toString(), { expires: 365 });
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <SettingsPopup
          onClose={() => setShowPopup(false)}
          onSave={handleSaveSettings}
          initialLightningHistoryDays={Number(Cookies.get('lightningHistoryDays') || '7')}
        />
      )}
    </>
  );
};

export default SettingsControl;