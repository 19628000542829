import React, { useEffect, useState } from 'react';
import { getUserProfile, requestPasswordReset, signOut } from '../../api/apiService';
import { useNavigate } from 'react-router-dom';
import styles from './UserProfilePage.module.css';

interface UserInfo {
  username: string;
  email: string;
  company: string;
  is_active: boolean;
}

const UserProfile: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [resetMessage, setResetMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const info = await getUserProfile();
      setUserInfo(info as UserInfo);
    } catch (error) {
      console.error('Failed to fetch user info:', error);
    }
  };

  const handleRequestPasswordReset = async () => {
    if (userInfo) {
      try {
        await requestPasswordReset(userInfo.email);
        setResetMessage('Password reset email sent, please check your email inbox.');
      } catch (error) {
        console.error('Failed to request password reset:', error);
        setResetMessage('Failed to request password reset. Please try again later.');
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      // Redirect to login page or update app state
      window.location.href = '/login'; // Or use your routing method
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  };

  const handleBackToMap = () => {
    navigate('/map');
  };

  if (!userInfo) {
    return <div className={styles.loading}>Loading user info...</div>;
  }

  const backgroundClass = userInfo.company === 'SAS' ? styles.sasBackground : '';

  return (
    <div className={`${styles.userProfile} ${backgroundClass}`}>
      <h2>User Profile</h2>
      <div className={styles.userInfo}>
        <p><strong>Username:</strong> {userInfo.username}</p>
        <p><strong>Email:</strong> {userInfo.email}</p>
        <p><strong>Company:</strong> {userInfo.company}</p>
        <p>
          <strong>Status:</strong> 
          {userInfo.is_active ? (
            <span className={styles.active}> Active</span>
          ) : (
            <span className={styles.inactive}> Inactive</span>
          )}
        </p>
      </div>
      <div className={styles.passwordResetSection}>
        <button 
          className={styles.changePasswordBtn} 
          onClick={handleRequestPasswordReset}
          title="An email with a password reset link will be sent to your registered email address."
        >
          Request Password Reset
        </button>
      </div>
      {resetMessage && (
        <div className={styles.resetMessage}>
          {resetMessage}
        </div>
      )}
      <div className={styles.buttonSection}>
        <button className={styles.backToMapBtn} onClick={handleBackToMap}>
          Back to Map
        </button>
        <button className={styles.signOutBtn} onClick={handleSignOut}>
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
