import { Control, ControlOptions } from "leaflet";
import { useMap } from "react-leaflet";
import { useEffect } from "react";
import userIcon from '../../assets/icons8-user-profile-64.png';
import './UserProfile.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const UserProfileControl = (props: ControlOptions) => {
  const map = useMap();
  const navigate = useNavigate();

  useEffect(() => {
    const UserControl = Control.extend({
      onAdd: () => {
        const container = document.createElement('div');
        container.className = "user-profile-control";
        container.title = "User Profile";
        container.innerHTML = `
          <img src="${userIcon}" alt="User Profile" width="38" height="38" />
        `;
        container.onclick = () => {
          navigate('/profile');
        };
        return container;
      }
    });

    // Merge the default position with any props passed
    const controlOptions: ControlOptions = {
      position: 'bottomleft',
      ...props
    };

    const control = new UserControl(controlOptions);
    control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map, props, navigate]);

  return null;
};

export default UserProfileControl;