// IncidentEdit.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Attachment, Damage, Incident, IncidentData } from '../../model/model';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from './IncidentEdit.module.css';
import { getAttachment, deleteAttachment, addAttachment } from '../../api/apiService'; // Assuming this function exists
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DamageVisualization, { DamageVisualizationRef } from '../DamageVisualization/DamageVisualization';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';

interface IncidentEditProps {
    incident: Incident;
    incidentData: IncidentData;
    incidentDamageData: Damage | null;
    incidentAttachments: Attachment[];
    onSubmit: (incident: Incident, damageData: Partial<Damage>) => void;
    onRemoveIncident: (incident: Incident) => void;
    onDeleteIncident: (incident: Incident) => void;
    onCancel: () => void;
    onAttachmentDeleted: () => void;
    onAttachmentUploaded: (newAttachment: Attachment) => void;
}

const IncidentEdit: React.FC<IncidentEditProps> = ({ incident, incidentData, incidentDamageData, incidentAttachments, onSubmit, onRemoveIncident, onDeleteIncident, onCancel, onAttachmentDeleted, onAttachmentUploaded }) => {
    const [editedIncident, setEditedIncident] = useState(incident);
    const [editedIncidentData, setEditedIncidentData] = useState(incidentData);
    const [deleteConfirmation, setDeleteConfirmation] = useState<{ open: boolean; attachmentId: string | null }>({ open: false, attachmentId: null });
    const [activeTab, setActiveTab] = useState(0);
    const damageVisualizationRef = useRef<DamageVisualizationRef>(null);
    const [uploadDialog, setUploadDialog] = useState({ open: false, file: null as File | null, description: '' });
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setEditedIncident(incident);
        setEditedIncidentData(incidentData);
    }, [incident, incidentData]);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;

        setEditedIncident(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const intensities = damageVisualizationRef.current?.getIntensities();
        const intensitiesString = intensities?.join('');
        const damageScore = damageVisualizationRef.current?.getDamageScore();
        const updatedDamageData: Partial<Damage> = {
            ...incidentDamageData,
            actual_damage_location: intensitiesString,
            actual_damage_score: damageScore ? parseInt(damageScore) : undefined
        };

        onSubmit(editedIncident, updatedDamageData);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
            handleSubmit(event);
        }
    };

    const handleRemove = () => {
        onRemoveIncident(incident);
    }

    const handleDownload = async (attachment: Attachment) => {
        try {
            const blob = await getAttachment(attachment.id);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = attachment.filename || 'download';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    const handleDeleteConfirmation = (attachmentId: string) => {
        setDeleteConfirmation({ open: true, attachmentId });
    };

    const handleConfirmDelete = async () => {
        if (deleteConfirmation.attachmentId) {
            await handleDeleteAttachment(deleteConfirmation.attachmentId);
        }
        setDeleteConfirmation({ open: false, attachmentId: null });
    };

    const handleCancelDelete = () => {
        setDeleteConfirmation({ open: false, attachmentId: null });
    };

    const handleDeleteAttachment = async (attachmentId: string) => {
        try {
            await deleteAttachment(attachmentId);
            const updatedIncidentData = {
                ...editedIncidentData,
                attachment_data: editedIncidentData.attachment_data?.filter(att => att.id !== attachmentId) ?? []
            };
            setEditedIncidentData(updatedIncidentData);
            onSubmit(editedIncident, updatedIncidentData.damage_data as Partial<Damage>);
            onAttachmentDeleted();
            enqueueSnackbar('Attachment deleted successfully', { variant: 'success' });
        } catch (error) {
            console.error('Failed to delete attachment:', error);
            if (error instanceof Error && 'response' in error && typeof error.response === 'object' && error.response && 'status' in error.response) {
                enqueueSnackbar('You do not have permission to delete this attachment', { variant: 'error' });
            } else {
                enqueueSnackbar('Failed to delete attachment, it may not belong to your organization.', { variant: 'error' });
            }
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        setUploadDialog({ open: true, file, description: '' });
    };

    const handleUploadConfirm = async () => {
        if (!uploadDialog.file) return;

        try {
            const formData = new FormData();
            formData.append('file', uploadDialog.file);

            const newAttachment: Attachment = {
                id: '', // This will be assigned by the server
                filename: uploadDialog.file.name,
                incident_id: incident.id,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                description: uploadDialog.description, // Add the description
            };

            const uploadedAttachment = await addAttachment(incident.id, newAttachment, uploadDialog.file);
            onAttachmentUploaded(uploadedAttachment);
            setUploadDialog({ open: false, file: null, description: '' });
        } catch (error) {
            console.error('Failed to upload attachment:', error);
        }
    };

    const handleUploadCancel = () => {
        setUploadDialog({ open: false, file: null, description: '' });
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form} onKeyDown={handleKeyDown}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="incident edit tabs">
                <Tab label="Main" />
                <Tab label="Damage" />
            </Tabs>

            {activeTab === 0 && (
                <div>
                    <div className={styles.row}>
                        <TextField className={styles.textField} name="id" label="ID" value={editedIncident.id} disabled />
                        <TextField className={styles.textField} name="Timestamp" label="UTC" value={editedIncident.timestamp} disabled />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <TextField name="hexident" label="Hexident" value={editedIncident.hexident} onChange={handleChange} />
                            <TextField name="callsign" label="Callsign" value={editedIncident.callsign} onChange={handleChange} />
                        </div>
                        <div className={styles.column}>
                            <TextField name="regnum" label="Registration" value={editedIncident.regnum} onChange={handleChange} />
                            <TextField name="flight_phase" label="Flight phase" value={editedIncident.flight_phase} onChange={handleChange} />
                        </div>
                        <div className={styles.column}>
                            <TextField name="event_type" label="Event type" value={editedIncident.event_type} onChange={handleChange} />
                            <TextField name="AOG" label="AOG" value={editedIncident.AOG} onChange={handleChange} />
                        </div>
                    </div>

                    <TextField
                        name="comment"
                        label="Comment"
                        multiline
                        minRows={4}
                        variant="outlined"
                        fullWidth
                        value={editedIncident.comment || ''}
                        onChange={handleChange}
                    />

                    <div className={styles.attachmentsSection}>
                        <h3>Attachments</h3>
                        {incidentAttachments && incidentAttachments.length > 0 ? (
                            <ul className={styles.attachmentList}>
                                {incidentAttachments.map((attachment: Attachment, index: number) => (
                                    <li key={attachment.id} className={styles.attachmentItem}>
                                        <Tooltip title={attachment.description ? `${attachment.description}` : 'No description'} arrow>
                                            <Button
                                                onClick={() => handleDownload(attachment)}
                                                variant="text"
                                                color="primary"
                                            >
                                                {attachment.filename || `Attachment ${index + 1}`}
                                            </Button>
                                        </Tooltip>
                                        <IconButton
                                            onClick={() => handleDeleteConfirmation(attachment.id)}
                                            color="secondary"
                                            aria-label="delete attachment"
                                            className={styles.deleteIcon}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No attachments available</p>
                        )}
                        <input
                            accept="*/*"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            type="file"
                            onChange={handleFileUpload}
                        />
                        <label htmlFor="raised-button-file">
                            <Button
                                variant="contained"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload
                            </Button>
                        </label>
                    </div>
                </div>
            )}

            {activeTab === 1 && (
                <div>
                    {incidentDamageData && (
                        <DamageVisualization
                            ref={damageVisualizationRef}
                            damageData={incidentDamageData}
                        />
                    )}
                </div>
            )}

            <div className={styles.buttonContainer}>
                <div className={styles.buttonSubGroup}>
                    <Button onClick={handleRemove}>Remove</Button>
                </div>
                <div className={styles.buttonSubGroup}>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="submit">Submit</Button>
                </div>
            </div>

            <Dialog
                open={deleteConfirmation.open}
                onClose={handleCancelDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this attachment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={uploadDialog.open} onClose={handleUploadCancel}>
                <DialogTitle>Upload Attachment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please provide a description for the attachment.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="normal"
                        label="Description"
                        type="text"
                        fullWidth
                        value={uploadDialog.description}
                        onChange={(e) => setUploadDialog({ ...uploadDialog, description: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUploadCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUploadConfirm} color="primary">
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>

        </form>
    );
};

export default IncidentEdit;
